import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import * as actions from '../redux/actions';
import * as categoryActions from '../../Category/redux/actions';

import {
  CustomButton, Typography, TextField, AutoComplete
} from '../../components';
import {
  Portlet, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import AssignedImages from './AssignedImages';
import Synonyms from './Synonyms';
import { EditIcon, CloseIcon } from '../../../assets/icons';



const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  item: {
    minWidth: '45%',
    maxWidth: '45%',
    [theme.breakpoints.down(1380)]: {
      minWidth: '70%',
      maxWidth: '70%',
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '85%',
      maxWidth: '85%',
    },
  },
  materialsTable: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    marginBottom: 15,
  },

  materialsLabel: {
    color: '#404040',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
}));

const MaterialsDetail = ({
  intl,
  categoryList,
  wasteTypeList,
  materialDetails,
  saveOneMaterialsRequest,
  locality,
  addMaterialRequest,
  fetchState,
  clearMaterialDetails,
  setIsDetailView,
  getAllMaterialsRequest,
  isCMLocality,
  setMaterialSnackbarMessage
}) => {
  const classes = useStyles();
  const [localMaterialDetails, setLocalMaterialDetails] = React.useState(materialDetails ? materialDetails : {
    mat_id: null,
    mat_nm: '',
    mat_cat_id: '',
    mat_admin_nm: '',
    mat_nm: '',
    waste_type_id: '',
    mats_images: '[]',
    mat_synonyms: [],
    cms_locality_id: locality.cms_locality_id,
  }
  );
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [categoryError, setCategoryError] = React.useState(false);
  const [synonymsHasChanged, setSynonymsHasChanged] = React.useState(false);

  useEffect(() => {
    if (materialDetails && !materialDetails.mat_id) {
      setIsEditing(true);
    }
  }, [materialDetails]);

  useEffect(() => {
    if (fetchState === 'succeed-request-save-material' && isSaving) {
      getAllMaterialsRequest({ locality: locality.cms_locality_id });
      setTimeout(() => {
        goBack();
      }, 1000);

    }
  }, [fetchState, isSaving]);

  const goBack = () => {
    clearMaterialDetails();
    setIsDetailView(false);
  };



  const handleCategoryChange = (category) => {
    const wasteType = wasteTypeList.find(type => type.waste_type_id === category.waste_type_id);
    setLocalMaterialDetails({ ...localMaterialDetails, mat_cat_id: category.mat_cat_id, waste_type_id: wasteType.waste_type_id });
    setCategoryError(false);
  };

  const handleWasteTypeChange = (wasteType) => {
    setLocalMaterialDetails({ ...localMaterialDetails, waste_type_id: wasteType.waste_type_id });
  };

  const handleSynonymsChange = (synonyms) => {
    setSynonymsHasChanged(true);
    setLocalMaterialDetails({ ...localMaterialDetails, mat_synonyms: synonyms });
  };

  const handleSave = () => {
    if (!localMaterialDetails.mat_cat_id) {
      setCategoryError(true);
      return;
    }

    if (synonymsHasChanged) {
      const synonymsSet = new Set(localMaterialDetails.mat_synonyms);
      if (synonymsSet.size !== localMaterialDetails.mat_synonyms.length) {
        setMaterialSnackbarMessage({ message: 'Error: Duplicate synonyms found.', status: 'error' });
        setTimeout(() => {
          setMaterialSnackbarMessage({ message: null, status: 'success' });
        }, 1000);

        return;
      }
      localMaterialDetails.mat_synonyms_has_changed = true;
    } else {
      localMaterialDetails.mat_synonyms_has_changed = false;
    }

    setIsSaving(true);

    if (!localMaterialDetails.mat_admin_nm) {
      localMaterialDetails.mat_admin_nm = localMaterialDetails.mat_nm;
    }

    if (!localMaterialDetails.mat_id) {
      addMaterialRequest(localMaterialDetails);
    } else {
      saveOneMaterialsRequest(localMaterialDetails);
    }

  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (event) => {
    setLocalMaterialDetails({ ...localMaterialDetails, mat_nm: event });
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  return (
    <>
      {
        localMaterialDetails && (
          <div key={localMaterialDetails.mat_id || 'new'}>
            <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
              <PortletHeader
                className={`${classes.tableLabel} w-100`}
                title={(
                  <div className="d-flex align-items-center w-100 ">
                    {isEditing ? (
                      <TextField
                        value={localMaterialDetails.mat_nm}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        autoFocus
                      />
                    ) : (
                      <Typography variant="h1" className={`${classes.materialsLabel}`}>
                        {localMaterialDetails.mat_nm || 'New Material'}
                      </Typography>
                    )}
                    <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={isEditing ? handleNameBlur : handleEditClick}>
                      {isEditing ? <CloseIcon style={{fontSize: '1.25rem' }} /> : <EditIcon style={{fontSize: '1.25rem' }} />}
                    </div>
                  </div>
                )}
              />
            </Portlet>
            <div className="row">
              <div className="col-6">
                <AutoComplete
                  title={intl.formatMessage({
                    id: 'GENERAL.CATEGORY',
                  })}
                  options={categoryList}
                  value={categoryList.find(category => category.mat_cat_id === localMaterialDetails.mat_cat_id)}
                  onChange={handleCategoryChange}
                  optionTitle="mat_cat_admin_nm"
                  optionId="mat_cat_id"
                  invalidText={categoryError ? 'Category is required' : ''}
                  error={categoryError ? true : false}
                  isDisable={isSaving}
                />
              </div>
              <div className="col-6">
                {
                  localMaterialDetails.waste_type_id && (
                    <AutoComplete
                      title={intl.formatMessage({
                        id: 'GENERAL.DISPOSCAL.TYPE',
                      })}
                      options={wasteTypeList}
                      value={localMaterialDetails.waste_type_id ? wasteTypeList.find(type => type.waste_type_id == localMaterialDetails.waste_type_id) : wasteTypeList[0]}
                      onChange={handleWasteTypeChange}
                      optionTitle="waste_type_admin_nm"
                      optionId="waste_type_id"
                      isDisable={true}
                    />
                  )
                }
                {
                  !localMaterialDetails.waste_type_id && (
                    <AutoComplete
                      title={intl.formatMessage({
                        id: 'GENERAL.DISPOSCAL.TYPE',
                      })}
                      options={wasteTypeList}
                      value={wasteTypeList.find(type => type.waste_type_id == localMaterialDetails.waste_type_id)}
                      onChange={handleWasteTypeChange}
                      optionTitle="waste_type_admin_nm"
                      optionId="waste_type_id"
                      isDisable={true}
                    />
                  )
                }
              </div>
            </div>
            <div className="row">
              <AssignedImages
                images={JSON.parse(localMaterialDetails.mats_images)}
                mat_id={localMaterialDetails.mat_id}
                localMaterialDetails={localMaterialDetails}
                setLocalMaterialDetails={setLocalMaterialDetails}
                isSaving={isSaving}
              />
            </div>
            {
              isCMLocality && locality && locality?.is_parent === 't' &&(
                <div className="row">
                  <Synonyms matSynonyms={localMaterialDetails.mat_synonyms} onSynonymsChange={handleSynonymsChange} />
                </div>
              )
            }

            <div className="d-flex justify-content-end mt-3">
              <CustomButton
                paddingVertical={4}
                variant={'contained'}
                label={'Save'}
                style={{ maxWidth: '180px' }}
                onClick={handleSave}
                disabled={isSaving}
              />
            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  categoryList: store.materials.categoryList,
  wasteTypeList: store.materials.wasteTypeList,
  materialDetails: store.materials.materialDetails,
  locality: store.common.locality,
  fetchState: store.materials.fetchState,
  isCMLocality: store.materials.isCMLocality,

});

export default injectIntl(connect(mapStateToProps, { ...actions, ...categoryActions })(MaterialsDetail));

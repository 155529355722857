import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { utils, writeFile } from 'xlsx-js-style';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import AddIcon from '@material-ui/icons/Add';
import { Dialog } from '../../components';

import * as actions from '../redux/actions';

import {
  CustomButton, Typography, TextField, Pagination
} from '../../components';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  item: {
    minWidth: '45%',
    maxWidth: '45%',
    [theme.breakpoints.down(1380)]: {
      minWidth: '70%',
      maxWidth: '70%',
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '85%',
      maxWidth: '85%',
    },
  },
  clearButton: {
    alignItems: 'start',
    height: 50,
    width: 162,
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  materialsTable: {
    // margin: '0 16px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    marginBottom: 15,
  },
  tableBody: {
    padding: '0 !important'
  },
  materialsLabel: {
    color: '#6C7293',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  addMaterial: {
    color: '#0086C2',
    marginLeft: 10,
    cursor: 'pointer',
  },
  topButtonContainer: {
    display: 'flex',
    gap: 15,
    maxWidth: '70%',
  },
  searchBox: {
    flex: 1,
    width: 400,
    [theme.breakpoints.down('800')]: {
      // width: '100%',
      marginBottom: '20px'
    },
  },
  downloadButton: {
    maxWidth: 280,
    minWidth: '45%',
    justifyContent: 'flex-end',

  },
  searchButton: {
    // maxWidth: 280,
    maxWidth: '25%',
    justifyContent: 'flex-end',

  },
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  detailReportButton: {
    width: '80%',
  },
}));

const MaterialsTable = ({
  intl,
  setIsDetailView,
  materials,
  locality,
  getOneMaterialDetailsRequest,
  deleteOneMaterialRequest,
  isCMLocality,
}) => {
  const classes = useStyles();
  const [filteredMaterials, setFilteredMaterials] = React.useState(materials);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [page, setPage] = React.useState(1);


  const handleViewMaterials = (mat_id) => {
    getOneMaterialDetailsRequest({ locality: locality.cms_locality_id, mat_id });
    setIsDetailView(true);
  }

  const handleSearch = () => {
    const searchTermLower = searchTerm.toLowerCase();
    const filtered = materials.filter(material =>
      material.mat_admin_nm && material.mat_admin_nm.toLowerCase().includes(searchTermLower)
    );
    setFilteredMaterials(filtered);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const exportData = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    // Add header
    utils.sheet_add_aoa(ws, [[
      { v: 'Materials', t: "s", s: { font: { name: "Calibri", sz: 15, bold: true }, alignment: { wrapText: true } } },
    ]], { origin: -1 });

    materials.forEach((item) => {
      utils.sheet_add_aoa(ws, [[
        { v: `${item.mat_nm}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
      ]], { origin: -1 });
    });

    const wscols = [{ wch: 40 }];
    ws['!cols'] = wscols;
    utils.book_append_sheet(wb, ws, 'Materials');
    writeFile(wb, 'MaterialsList.xlsx');
  };

  const onSearchTermChange = (event) => {
    setSearchTerm(event);
    if (!event) {
      setFilteredMaterials(materials);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = (mat_id) => {
    deleteOneMaterialRequest({ locality: locality.cms_locality_id, mat_id });
    setFilteredMaterials(filteredMaterials.filter(material => material.mat_id !== mat_id));
  };

  const handleAddMaterial = () => {
    setIsDetailView(true);
  };


  return (
    <div>
      <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
        <PortletHeader
          className={`${classes.tableLabel} w-100`}
          title={(
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div className='d-flex w-100'>
                <Typography variant="h1" className={`${classes.materialsLabel}`}>
                  Materials
                </Typography>
                {
                  isCMLocality && locality && locality?.is_parent === 't' &&(
                    <Typography variant="h1" className={`${classes.addMaterial}`} onClick={handleAddMaterial}>
                      <AddIcon />
                      Add a Material
                    </Typography>
                  )
                }

              </div>

              <div className={classes.topButtonContainer}>
                <div className={`${classes.searchBox}`}>
                  <TextField
                    label={intl.formatMessage({ id: 'CATEGORY.HEADER.MATERIAL_SEARCH' })}
                    value={searchTerm}
                    onChange={onSearchTermChange}
                    onKeyPress={handleKeyPress}
                    hasClear
                    invalidText={searchTerm && filteredMaterials.length === 0 ? 'No materials found' : ''}
                  />
                </div>
                <div className={classes.topButtonContainer} style={{ maxHeight: '52.44px' }}>
                  <CustomButton
                    paddingVertical={4}
                    className={classes.searchButton}
                    variant={'outlined'}
                    label={'Search'}
                    onClick={handleSearch}
                  />
                  <CustomButton
                    paddingVertical={4}
                    className={classes.downloadButton}
                    variant={'contained'}
                    label={'Download the Material List'}
                    onClick={exportData}
                  />
                </div>
              </div>
            </div>
          )}
        />
        {
          filteredMaterials && (
            <PortletBody className={`${classes.tableBody}`}>
              <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                <div className="col-10">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    Name
                  </Typography>
                </div>
                <div className="col-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  </Typography>
                </div>
              </div>
              <div className={classes.dataTable}>
                {
                  filteredMaterials.slice((page - 1) * 25, page * 25).map((material) => (
                    <div
                      key={material.mat_id}
                      className={`row d-flex align-items-center pt-3 pb-3 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                    >
                      <div className="col-10">
                        <div className='d-flex'>
                          <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                            {material.mat_nm}
                          </Typography>
                        </div>
                      </div>
                      <div className="col row d-flex align-items-center mr-2">
                        <CustomButton
                          paddingVertical={4}
                          className={classes.detailReportButton}
                          variant={`contained`}
                          label={`View/Edit`}
                          onClick={() => handleViewMaterials(material.mat_id)}
                        />
                        {isCMLocality && (
                          <Dialog
                            button={(
                              <DeleteIcon style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '1.25rem' }} />
                            )}
                            title={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                            content={(
                              <Typography variant="body">
                                {intl.formatMessage({ id: 'MATERIALS.MODAL.DELETE' })}
                              </Typography>
                            )}
                            submitText={intl.formatMessage({ id: 'GENERAL.YES' })}
                            cancelText={intl.formatMessage({ id: 'GENERAL.NO' })}
                            handleSubmit={() => handleDelete(material.mat_id)}
                          />
                        )}
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                <Pagination
                  page={page}
                  rowsPerPage={25}
                  handlePageChange={handleChangePage}
                  dataLength={filteredMaterials.length}
                  hideRowsPerPage
                />
              </div>
            </PortletBody>
          )
        }
      </Portlet>
    </div>
  );
};

const mapStateToProps = (store) => ({
  materials: store.materials.materials,
  locality: store.common.locality,
  isCMLocality: store.materials.isCMLocality,
});

export default injectIntl(connect(mapStateToProps, actions)(MaterialsTable));

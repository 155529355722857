import cts from '../../../../crud/cts';

export const getEducationBlogsData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  `blogs?project_id=${project_id}&district_id=${district_id}`
);

/**
 * Blog API to delete a blog
 */
export const blogDelete = async ({ blogId, project_id, district_id }) => cts(
  'delete',
  `blogs?project_id=${project_id}&district_id=${district_id}&blog_id=${blogId}`,
);



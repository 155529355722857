import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import * as actions from '../redux/actions';

import {
  CustomButton, Typography, TextField, AutoComplete, IconButton
} from '../../components';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

import CloseIcon from '@material-ui/icons/Close';
import { getClientLocation } from '../../../utils';


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E7F2FE',
    minHeight: '150px',
    width: '100%',
    padding: '20px',
    display: 'flex',
  },
  emptyContainer: {
    backgroundColor: '#F2F3F8',
    minHeight: '150px',
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #ccc',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    overflowX: 'visible',
    transform: 'rotateX(180deg)',
  },
  item: {
    minWidth: '45%',
    maxWidth: '45%',
    [theme.breakpoints.down(1380)]: {
      minWidth: '70%',
      maxWidth: '70%',
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '85%',
      maxWidth: '85%',
    },
  },
  clearButton: {
    alignItems: 'start',
    height: 50,
    width: 162,
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  body: {
    margin: '25px 0px 0px 0px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',

  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    marginBottom: 15,
  },
  tableBody: {
    padding: '0 !important'
  },
  title: {
    color: '#6C7293',
    textTransform: 'uppercase'

  },
  topButtonContainer: {
    display: 'flex',
    gap: 15,
  },
  searchBox: {
    flex: 1,
    width: 400,
    [theme.breakpoints.down('800')]: {
      // width: '100%',
      marginBottom: '20px'
    },
  },
  downloadButton: {
    width: 280,
    justifyContent: 'flex-end',

  },
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '1rem',
    padding: '0px 10px',
    maxWidth: '250px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: '5px',
  },
  image: {
    width: '200px',
    height: '200px',
    backgroundColor: '#FFFFFF',
  },
}));

const AssignedImages = ({
  intl,
  images,
  uploadMaterialImageRequest,
  mat_id,
  locality,
  localMaterialDetails,
  setLocalMaterialDetails,
  isSaving,
  setMaterialSnackbarMessage
}) => {
  const userLocation = getClientLocation();

  const classes = useStyles();
  const baseUrl = userLocation === 'US' ? process.env.REACT_APP_API_BASE_URL_US : process.env.REACT_APP_API_BASE_URL;
  const [imageSizes, setImageSizes] = useState({});
  const [localImages, setLocalImages] = useState(images);
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setMaterialSnackbarMessage({ message: error, status: 'error' });
    } else {
      setMaterialSnackbarMessage({ message: null, status: 'success' });
    }
  }, [error]);

  const getFileName = (path) => {
    return path.substring(path.lastIndexOf('/') + 1);
  };

  const sanitizeFileName = (fileName) => {
    const timestamp = Date.now();
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const baseName = fileName.substring(0, fileName.lastIndexOf('.')).replace(/[^a-zA-Z0-9]/g, '_');
    return `${baseName}_${timestamp}${fileExtension}`;
  };

  const validateImageSize = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 600 && img.height === 600) {
          resolve(true);
        } else {
          reject(new Error('Image must be 600x600 pixels'));
        }
      };
      img.src = URL.createObjectURL(file);
    });
  };

  useEffect(() => {
    localImages.forEach((image) => {
      const img = new Image();
      img.onload = () => {
        const sizeInKB = ((img.naturalWidth * img.naturalHeight * 4) / 1024).toFixed(2);
        setImageSizes((prevSizes) => ({
          ...prevSizes,
          [image.id]: `${sizeInKB} KB`,
        }));
      };
      img.src = image.image_path.startsWith('blob:') ? image.image_path : `${baseUrl}/gallery/${image.image_path}`;
    });
  }, [localImages, baseUrl]);

  const handleRemoveImage = (removedimages) => {
    const updatedImages = localImages.filter(image => image.image_path !== removedimages.image_path);
    setLocalImages(updatedImages);

    setLocalMaterialDetails({ ...localMaterialDetails, mats_images: JSON.stringify(updatedImages) });
  };

  const handleDrop = async (event) => {
    setError('');
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newImages = [];
    for (const file of files) {
      try {
        await validateImageSize(file);
        const sanitizedFileName = sanitizeFileName(file.name);
        newImages.push({
          id: `new-${newImages.length}`,
          image_path: URL.createObjectURL(file),
          file_name: sanitizedFileName,
        });
      } catch (error) {
        setError(error.message);
      }
    }
    setLocalImages([...localImages, ...newImages]);

    newImages.forEach((image, index) => {
      const formData = new FormData();
      formData.append('image', files[index], image.file_name);
      uploadMaterialImageRequest({ locality: locality.cms_locality_id, mat_id: mat_id, image: formData });

      // Update mats_images in MaterialDetails
      const updatedImages = [...JSON.parse(localMaterialDetails.mats_images), { image_path: `clients_images/${image.file_name}` }];
      setLocalMaterialDetails({ ...localMaterialDetails, mats_images: JSON.stringify(updatedImages) });
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileUpload = async (event) => {
    setError('');
    const files = event.target.files;
    const newImages = [];
    for (const file of files) {
      try {
        await validateImageSize(file);
        const sanitizedFileName = sanitizeFileName(file.name);
        newImages.push({
          id: `new-${newImages.length}`,
          image_path: URL.createObjectURL(file),
          file_name: sanitizedFileName,
        });
      } catch (error) {
        setError(error.message);
      }
    }
    setLocalImages([...localImages, ...newImages]);

    newImages.forEach((image, index) => {
      const formData = new FormData();
      formData.append('image', files[index], image.file_name);
      uploadMaterialImageRequest({ locality: locality.cms_locality_id, mat_id: mat_id, image: formData });

      // Update mats_images in MaterialDetails
      const updatedImages = [...JSON.parse(localMaterialDetails.mats_images), { image_path: `clients_images/${image.file_name}` }];
      setLocalMaterialDetails({ ...localMaterialDetails, mats_images: JSON.stringify(updatedImages) });
    });
  };

  const handleUploadButtonClick = () => {
    document.getElementById('upload-button').click();
  };

  return (
    <div style={{ width: '100%' }}>
      <Portlet className={`w-100 position-relative ${classes.body}`}>
        <PortletHeader
          className={`${classes.tableLabel} w-100`}
          title={(
            <div className="d-flex align-items-center w-100 justify-content-between">
              <Typography variant="h1" className={`${classes.title}`}>
                Assigned Images
              </Typography>
            </div>
          )}
        />
      </Portlet>
      <div
        className={localImages.length === 0 ? classes.emptyContainer : classes.container}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {localImages.length === 0 ? (
          <>
            <Typography variant="h2" style={{ color: '#0086C2' }}>
              Drag & Drop image or browse to upload
            </Typography>
            <Typography variant="body2" style={{ color: '#6C7293', marginTop: '10px' }}>
              Please upload your images in this size - 600 pixels x 600 pixels with a transparent background. PNG format preferred.
            </Typography>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-button"
              type="file"
              multiple
              onChange={handleFileUpload}
            />
            <CustomButton
              paddingVertical={4}
              variant={'contained'}
              label={'Upload Image'}
              style={{ marginTop: '20px', width: '200px' }}
              onClick={handleUploadButtonClick}
            />
          </>
        ) : (
          <>
            {localImages.map((image) => (
              <div key={image.id} className={classes.imageContainer}>
                <IconButton className={classes.closeButton} tooltip="Delete Image" onClick={() => handleRemoveImage(image)}>
                  <CloseIcon />
                </IconButton>
                <img src={image.image_path.startsWith('blob:') ? image.image_path : `${baseUrl}/gallery/${image.image_path}`} alt={getFileName(image.image_path)} className={`mb-2 ${classes.image}`} />
              </div>
            ))}
            <div
              className={classes.imageContainer}
              style={{ border: '2px dashed #ccc', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}
              onClick={handleUploadButtonClick}
            >
              <Typography variant="h2" style={{ color: '#0086C2' }}>
                Drag & Drop image or browse to upload
              </Typography>
              <Typography variant="body2" style={{ color: '#6C7293', marginTop: '10px' }}>
                Please upload your images in this size - 600 pixels x 600 pixels with a transparent background. PNG format preferred.
              </Typography>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-button"
                type="file"
                multiple
                onChange={handleFileUpload}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
});



export default injectIntl(connect(mapStateToProps, actions)(AssignedImages));

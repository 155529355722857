import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';
import CampaignItem from '../../../Campaign/components/CampaignItem';
import { DoughnutChart } from '../../components';

import { ListLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '../components';



import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';


import { Switch, Typography } from '../../../components';



const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },
  //.group
  group: {
    display: "flex",
    gap: "10px"
  },
  //.groupItem
  groupItem: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #E7ECEF",
    borderRadius: 9,
    ChangeTheColorBorderRadius: "8px",
    padding: "12px",
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: "#63666A",
    ChangeTheColorFontFamily: "sans-serif"
  },
  //.group__title
  groupTitle: {
    fontWeight: "600"
  },
  //.group__number
  groupNumber: {
    fontSize: "20px",
    fontWeight: "800",
    marginTop: "6px",
    marginBottom: "6px"
  },
  //.group__change
  groupChange: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "600"
  },
  //.group__arrow
  groupArrow: {
    marginRight: "6px"
  },
  ///* Item shows increase */
  groupChangePositive: {
    color: "green"
  },
  ///* Item shows decrease */
  groupChangeNegativet: {
    color: "red"
  },
  //.group__change--negative .group__arrow
  groupChangeNegativeGroupArrowClass: {
    transform: "rotate(180deg)"
  },
  ///* Blue item */
  groupItemHighlighted: {
    backgroundColor: "#2A95FF",
    color: "white"
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px',
    fontSize: '14px',
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px',
    fontSize: '14px',
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#409A5F'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600,
    fontSize: 14,
  },
  list__percentage: {
    gap: 75
  },
  
}));

const Top5Topics = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();




  let labels;
  let totalTop5;
  let top5Values;


  /**
* Menu and menuitem functions.
*/
const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);

  };

  const viewDetailsHandler = (e) => {
    history.push('/report/materials/detail');
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  if (reportData && reportData.topic_top_5 && reportData.topic_top_5.length) {

    // labels = reportData.topic_top_5.map(({title}) => title);
    labels = reportData.topic_top_5.filter(function (e) { return e.title.length }).map((e) => {
      return e.title
    })
    totalTop5 = reportData.topic_top_5.map((topic) => {
      return topic.engagement.value
    }).reduce((prev, next) => {
      return prev + next;
    });
    top5Values = reportData.topic_top_5.filter(function (e) { return e.engagement.value }).map((e) => {
      return e.engagement.value
    })
  }


  return (
    <>

      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Top 5 Topics
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                From blogs to quizzes to videos and more, most content on the Recycle Coach platform is designed to educate residents about a wide variety of topics related to managing household waste. This report shows you the topics residents engaged with this month
              </Typography>
            )}
          />
        </div>
        <PortletBody>
          <div className="row mt-5">
            <div className="col-6 col-xl-5">
              <DoughnutChart
                isLoading={isLoading}
                total={totalTop5 ? totalTop5.toLocaleString() : totalTop5}
                insideText="Total Topics highlighted this month"
                labels={labels}
                values={top5Values}
                colors={['#2A95FF', '#A663FF', '#97CCFF', '#00C192', '#FFCD5B']}
              />
            </div>
            <div className="col-6 col-xl-6">
              {
                isLoading ?
                  <ListLoading /> :
                  <div className={`${classes.lists} justify-content-between`}>
                    <div className={classes.list}>
                      <div className={classes.list__heading}>Topics</div>
                      <div className={classes.list__rows}>
                        {
                          reportData?.topic_top_5?.map((topic, index) => {
                            return (
                              <div className={classes.list__row}>
                                <div className={`${classes.list__legend} ${index + 1 === 1 ? classes.list__legend1 : index + 1 === 2 ? classes.list__legend2 : index + 1 === 3 ? classes.list__legend3 : index + 1 === 4 ? classes.list__legend4 : classes.list__legend5}`}>{index + 1}</div>
                                <div className={classes.list__topic}>{topic.title}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={`d-flex justify-content-between ${classes.list__percentage}`}> 
                    <div className={classes.list}>
                      <div className={classes.list__heading}>Impressions</div>
                      <div className={classes.list__rows}>
                        {
                          reportData?.topic_top_5?.map((topic, index) => {
                            return (
                              <div className={classes.list__row}>
                                <div className={classes.list__number}>{topic.exposure.value.toLocaleString()}</div>
                                <div className={`${classes.list__change} ${Number(topic.exposure.diff) >= 0 ? classes.list__changePositive : classes.list__changeNegative}`}>
                                  <div className={classes.list__arrow}>{topic.exposure.diff >= 0 ? '↑' : '↓'}</div>
                                  <div className={classes.list__percentage}>{topic.exposure.diff}%</div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={classes.list}>
                      <div className={classes.list__heading}>Engagement</div>
                      <div className={classes.list__rows}>
                        {
                          reportData?.topic_top_5?.map((topic, index) => {
                            return (
                              <div className={classes.list__row}>
                                <div className={classes.list__number}>{topic.engagement.value.toLocaleString()}</div>
                                <div className={`${classes.list__change} ${Number(topic.exposure.diff) >= 0 ? classes.list__changePositive : classes.list__changeNegative}`}>
                                  <div className={classes.list__arrow}>{topic.exposure.diff >= 0 ? '↑' : '↓'}</div>
                                  <div className={classes.list__percentage}>{topic.engagement.diff}%</div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    </div>
                  </div>
              }


            </div>

          </div>

        </PortletBody>
      </Portlet>

    </>
  );

};

export default injectIntl(Top5Topics);

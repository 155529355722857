import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../components';
import * as actions from '../../redux/actions';
import * as perpetualSchedulesActions from '../../Schedule/PerpetualSchedules/redux/actions';
import { hasCompletedPerpetualSetup } from '../../Schedule/helper';
import { useLastLocation } from 'react-router-last-location';


const PerpetualSchedulesBanner = ({
  district, setDistrictAction, municipalities, getPerpetualSchedulesRequest, perpetualSchedulesDistrict, getSeasonalListRequest, seasonalList
}) => {

  const [displayBanner, setDisplayBanner] = useState(false);
  const [psRedirecy, setPsRedirect] = useState(true);
  const lastLocation = useLastLocation();
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (lastLocation && (lastLocation.pathname.includes('login' ) || lastLocation.pathname === '/')) {
        if (psRedirecy && perpetualSchedulesDistrict && perpetualSchedulesDistrict?.length >= 0 && psRedirecy) {
          history.push('/schedules?displayPSBanner=true');
        }
      }
    }, 800);
    
    // Cleanup function to clear the timeout if called again
    return () => clearTimeout(timeout);
  }, [displayBanner, seasonalList, perpetualSchedulesDistrict, municipalities, psRedirecy]);

  useEffect(() => {
    if (district?.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const districts = municipalities.map((municipality) => municipality.district_id);
      getPerpetualSchedulesRequest(districts);
      // setDisplayBanner(true);
      setPsRedirect(true);
    }
  }, [district]);

  useEffect(() => {
    if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      const districts = perpetualSchedulesDistrict.map((district) => `${district.project_id}#$#${district.district_id}`).join(',');
      getSeasonalListRequest(districts.split(','));
    }

  }, [perpetualSchedulesDistrict, municipalities]);

  useEffect(() => {
    if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      if (seasonalList && seasonalList?.seasonal_list.length) {
        const completedSetup = hasCompletedPerpetualSetup(seasonalList);
        if (completedSetup) {
          setDisplayBanner(false);
          setPsRedirect(false);
        } else {
          setDisplayBanner(true);
          setPsRedirect(true);
        }
      } else {
        setDisplayBanner(false);
        setPsRedirect(false);
      }
    } else if (municipalities && municipalities?.length && (!perpetualSchedulesDistrict || !perpetualSchedulesDistrict?.length)) {
      setDisplayBanner(true);
      setPsRedirect(true);
    } else if (municipalities && municipalities?.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict?.length && municipalities?.length !== perpetualSchedulesDistrict?.length) {
      setDisplayBanner(true);
      setPsRedirect(true);
    }
  }, [seasonalList]);

  return (
    <>
      {
        displayBanner && (
          <Portlet className="p-4">
          <Typography
            style={{ fontSize: '13px' }}
            variant="bodySemibold"
            className="mb-4"
          >
            <strong>In lieu of sending us your 2025 calendar</strong>, you are now able to note your seasonal information and identify which holidays will affect collection using the <strong>Perpetual Schedules</strong> tool. Navigate to the <strong style={{ textDecoration: 'underline' }}> Schedules Tab</strong> where you can access this feature.
          </Typography>
    
          <div className="d-flex align-items-center mt-1">
            <Typography
              style={{ fontSize: '13px' }}
              variant="bodySemibold"
              className="mb-4"
            >
              Set up your schedules today for an easy transition into 2025!
            </Typography>
          </div>
    
        </Portlet>
        )
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  perpetualSchedulesDistrict: store.perpetualSchedules.districts,
  seasonalList: store.perpetualSchedules.seasonalList,
});

export default connect(mapStateToProps, { ...actions, ...perpetualSchedulesActions })(PerpetualSchedulesBanner);

const actionTypes = {
  GET_ALL_EDUCATION_BLOGS_REQUEST: 'GET_ALL_EDUCATION_BLOGS_REQUEST',
  GET_ALL_EDUCATION_BLOGS_RECEIVE: 'GET_ALL_EDUCATION_BLOGS_RECEIVE',
  GET_ALL_EDUCATION_BLOGS_FAIL: 'GET_ALL_EDUCATION_BLOGS_FAIL',
  SET_CURRENT_BLOG_INDEX: 'SET_CURRENT_BLOG_INDEX',
  BLOG_DELETE_REQUEST: 'BLOG_DELETE_REQUEST',
  BLOG_DELETE_RECEIVE: 'BLOG_DELETE_RECEIVE',
  BLOG_DELETE_FAIL: 'BLOG_DELETE_FAIL',
};

export default actionTypes;

const actionTypes = {
  // Category actionTypes for setting loading status
  SET_CATEGORY_LOADING_ACTION: 'SET_CATEGORY_LOADING_ACTION',
  SET_CATEGORY_SNACKBAR_ACTION: 'SET_CATEGORY_SNACKBAR_ACTION',

  GET_ALL_MATERIALS_REQUEST: 'GET_ALL_MATERIALS_REQUEST',
  GET_ALL_MATERIALS_RECEIVE: 'GET_ALL_MATERIALS_RECEIVE',
  GET_ALL_MATERIALS_FAIL: 'GET_ALL_MATERIALS_FAIL',

  GET_ONE_MATERIAL_DETAILS_REQUEST: 'GET_ONE_MATERIAL_DETAILS_REQUEST',
  GET_ONE_MATERIAL_DETAILS_RECEIVE: 'GET_ONE_MATERIAL_DETAILS_RECEIVE',
  GET_ONE_MATERIAL_DETAILS_FAIL: 'GET_ONE_MATERIAL_DETAILS_FAIL',

  SAVE_ONE_MATERIAL_DETAILS_REQUEST: 'SAVE_ONE_MATERIAL_DETAILS_REQUEST',
  SAVE_ONE_MATERIAL_DETAILS_RECEIVE: 'SAVE_ONE_MATERIAL_DETAILS_RECEIVE',
  SAVE_ONE_MATERIAL_DETAILS_FAIL: 'SAVE_ONE_MATERIAL_DETAILS_FAIL',

  DELETE_ONE_MATERIAL_REQUEST: 'DELETE_ONE_MATERIAL_REQUEST',
  DELETE_ONE_MATERIAL_RECEIVE: 'DELETE_ONE_MATERIAL_RECEIVE',
  DELETE_ONE_MATERIAL_FAIL: 'DELETE_ONE_MATERIAL_FAIL',

  UPLOAD_MATERIAL_IMAGE_REQUEST: 'UPLOAD_MATERIAL_IMAGE_REQUEST',
  UPLOAD_MATERIAL_IMAGE_RECEIVE: 'UPLOAD_MATERIAL_IMAGE_RECEIVE',
  UPLOAD_MATERIAL_IMAGE_FAIL: 'UPLOAD_MATERIAL_IMAGE_FAIL',

  CLEAR_MATERIAL_DETAILS: 'CLEAR_MATERIAL_DETAILS',
  
  ADD_MATERIAL_REQUEST: 'ADD_MATERIAL_REQUEST',
  ADD_MATERIAL_RECEIVE: 'ADD_MATERIAL_RECEIVE',
  ADD_MATERIAL_FAIL: 'ADD_MATERIAL_FAIL',

  CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST: 'CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST',
  CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE: 'CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE',
  CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL: 'CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL',

  SET_MATERIAL_SNACKBAR_MESSAGE: 'SET_MATERIAL_SNACKBAR_MESSAGE',
  GET_MATERIAL_LOCALITY_CATEGORY_WASTE_REQUEST: 'GET_MATERIAL_LOCALITY_CATEGORY_WASTE_REQUEST',
  GET_MATERIAL_LOCALITY_CATEGORY_WASTE_RECEIVE: 'GET_MATERIAL_LOCALITY_CATEGORY_WASTE_RECEIVE',
  GET_MATERIAL_LOCALITY_CATEGORY_WASTE_FAIL: 'GET_MATERIAL_LOCALITY_CATEGORY_WASTE_FAIL',

};

export default actionTypes;

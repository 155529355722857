import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import actionTypes from './actionTypes';
import { getEducationBlogsData, blogDelete } from '../apis';

function* getEducationBlogsSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getEducationBlogsData, { project_id, district_id });
  try {
    yield put(actions.getAllEducationBlogsReceive(response.data));
  } catch (error) {
    yield put(actions.getAllEducationBlogsFail(response.error.detail.data.data));
  }
}

function* blogDeleteSaga({ payload: { blogId, project_id, district_id } }) {
  const response = yield call(blogDelete, { blogId, project_id, district_id });
  try {
    yield put(actions.blogDeleteReceive(response.data));
  } catch (error) {
    yield put(actions.blogDeleteFail(response.error));
  }
}

function* educationBlogsSagas() {
  yield takeLatest(actionTypes.GET_ALL_EDUCATION_BLOGS_REQUEST, getEducationBlogsSaga);
  yield takeLatest(actionTypes.BLOG_DELETE_REQUEST, blogDeleteSaga);

}

export default educationBlogsSagas;
